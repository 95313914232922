import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import AnimationSettings from "../../common/AnimationSettings";
import {motion} from 'framer-motion/dist/framer-motion'

export const CookiePolicy = () => {
    return (
        <motion.div className="route-container" key="3" {...AnimationSettings}>
            <Container className="Cookie-Policy mx-auto pb-5 my-5">
                <Row className="mb-2 mt-3">
                    <Col lg="8">
                        <h1 className="display-4 mb-4">Cookie policy</h1>
                        <hr className="t_border my-4 ml-0 text-left"/>
                    </Col>
                </Row>

                <div class=" mb-5">
                    <div>Effective Date: 23-Jul-2022</div>
                    <br/>
                    <div> Last Updated: 23-Jul-2022</div>
                </div>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h3>What are cookies?</h3>
                        <div>
                            This Cookie Policy explains what cookies are and how we use them, the types of cookies we
                            use
                            i.e,
                            the information we collect using cookies and how that information is used, and how to manage
                            the
                            cookie settings.
                            Cookies are small text files that are used to store small pieces of information. They are
                            stored
                            on
                            your device when the website is loaded on your browser. These cookies help us make the
                            website
                            function properly, make it more secure, provide better user experience, and understand how
                            the
                            website performs and to analyze what works and where it needs improvement.
                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h3>How do we use cookies?</h3>
                        <div>As most of the online services, our website uses first-party and third-party cookies for
                            several
                            purposes. First-party cookies are mostly necessary for the website to function the right
                            way,
                            and
                            they do not collect any of your personally identifiable data.
                            The third-party cookies used on our website are mainly for understanding how the website
                            performs,
                            how you interact with our website, keeping our services secure, providing advertisements
                            that
                            are
                            relevant to you, and all in all providing you with a better and improved user experience and
                            help
                            speed up your future interactions with our website.
                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 mt-3">
                    <Col>
                        <h3>Analytical Cookies</h3>
                        <div>
                            We use Google Analytics to track traffic of this website.
                        </div>
                    </Col>
                </Row>

                <Row className="mb-12 mt3">
                    <Col>
                        <h3>Manage cookie preferences</h3>
                        <div>
                            Different browsers provide different methods to block and delete cookies used
                            by websites. You can change the settings of your browser to block/delete the cookies. Listed
                            below
                            are the links to the support documents on how to manage and delete cookies from the major
                            web
                            browsers.
                            <br/>
                            <br/>
                            Chrome: <a
                            href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050 </a>
                            <br/>
                            <br/>
                            Safari: <a
                            href="https://support.apple.com/en-in/guide/safari/sfri11471/mac">https://support.apple.com/en-in/guide/safari/sfri11471/mac </a>
                            <br/>
                            <br/>
                            Firefox:
                            <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US</a>
                            <br/>
                            <br/>
                            Internet Explorer:
                            <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc</a>
                            <br/>
                            <br/>
                            If you are using any other web browser, please visit your browser’s official support
                            documents.
                        </div>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}
