const logotext = "Thomas";
const meta = {
    title: "Maximilian Thomas",
    description: "I’m John Doe data scientist _ Full stack devloper,currently working in Berlin",
};

const introdata = {
    title: "I’m Maximilian Thomas",
    animated: {
        first: "I love coding",
        second: "I develop websites",
        third: "I develop shops",
    },
    description: "Passionated developer, I love to create and code cool stuff. Currently working as a freelance-developer.",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "Profile",
    aboutme: "Self-motivated Developer adds high level of experience over more than 3 years\n" +
        "collaborating and working on multiple web-based projects. Passionate, hardworking\n" +
        "coder with penchant for developing Interfaces and API's that factor in unique\n" +
        "demands for accessibility, reachability and security. Organized approach to meeting\n" +
        "multiple, concurrent deadlines. Pulls from active knowledge of current technology\n" +
        "landscape to promote best practices in web development. Skilled at balancing\n" +
        "usability with aesthetic appeal.",
};
const worktimeline = [{
        jobtitle: "Fullstack-Developer",
        where: "Loewenstark Digital Group GmbH",
        date: "November 2021 - Present",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "PHP (Laravel/Symfony)",
        value: 100,
    },
    {
        name: "Startups & scalability",
        value: 85,
    },
    {
        name: "Test driven development",
        value: 80,
    },
    {
        name: "Javascript (React & Vue)",
        value: 80,
    },
    {
        name: "Google Cloud Platform",
        value: 85,
    },
    {
        name: "NodeJs",
        value: 85,
    },
    {
        name: "CSS / TailwindCSS",
        value: 85,
    },
    {
        name: "Shopware",
        value: 100,
    },
];

const services = [{
        title: "Fullstack-Web development",
        description: "Development of performant web applications. Professional experience in building API's with Laravel & Symfony PHP and interfaces with Vue and Reactjs.",
    },
    {
        title: "Shop-Development",
        description: "Development of Shopware shops. Strong experience in build Shops with Shopware5 & 6 and development of multiple plugins.",
    },
    {
        title: "Consulting",
        description: "Monitoring and mentoring to build scalable Applications. Also family with various cloud providers",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "hello@maximilian-thomas.com",
   // YOUR_FONE: "(555)123-4567",
    description: "Do you have a question ? Or want to work with me ? Feel free to contact me if you want to get in touch with me. I usually respond within the next 24 hours.",
    YOUR_SERVICE_ID: "service_wzoxutt",
    YOUR_TEMPLATE_ID: "template_shix519",
    YOUR_USER_ID: "9GzE_AtLwdqPXxG_A",
};

const socialprofils = {
    github: "https://github.com/thomasmax-97",
    linkedin: "https://www.linkedin.com/in/maximilian-thomas-852a98209/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
