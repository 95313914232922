import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {Home} from "../pages/home";
import {Portfolio} from "../pages/portfolio";
import {ContactUs} from "../pages/contact";
import {About} from "../pages/about";
import {CookiePolicy} from "../pages/cookie-policy"
import {LegalNotice} from "../pages/legal-notice";
import {Socialicons} from "../components/socialicons";
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import AnimationSettings from "../common/AnimationSettings";

const AnimatedSwitch = withRouter(({location}) => (
    <AnimatePresence initial={false}>
        <Switch location={location}>

            <Route exact path="/" component={Home}/>

            <Route path="/about" component={About}/>

            <Route path="/portfolio" component={Portfolio}/>

            <Route path="/contact" component={ContactUs}/>

            <Route path="/cookie-policy" component={CookiePolicy}/>

            <Route path="/legal-notice" component={LegalNotice}/>

            <Route path="*" component={Home}/>

        </Switch>
    </AnimatePresence>
));

function AppRoutes() {
    return (
        <div className="s_c">
            <AnimatedSwitch/>
            <Socialicons/>
        </div>
    );
}

export default AppRoutes;
